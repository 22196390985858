<template>
  <a-form
    ref="form"
    :model="form"
    :rules="rules"
    class="approval-form columns is-mini-gap is-multiline"
  >
    <div class="column is-12">
      <h2 class="has-text-primary has-text-weight-bold is-size-4">
        {{ $t('confirm') }}
      </h2>
    </div>
    <div class="column confirm-group is-12">
      <a-form-item name="evidenceMatch" class="mb-0">
        <a-checkbox v-model:checked="form.evidenceMatch">
          {{ $t('evidence matches') }}
        </a-checkbox>
      </a-form-item>

      <a-form-item name="instructionMatch" class="mb-0">
        <a-checkbox v-model:checked="form.instructionMatch">
          {{ $t('instruction matches') }}
        </a-checkbox>
      </a-form-item>
    </div>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-2x"
        style="min-width: 110px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        style="min-width: 110px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Approve') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "confirm": "Confirm to approve",
    "evidence matches": "Confirmed that the evidence matches the cost that was applied.",
    "instruction matches": "Confirmed that the applied cost and the instruction matches.",
    "Cancel": "Cancel",
    "Approve": "Approve",
    "Please confirm": "Please confirm"
  },
  "ja": {
    "confirm": "承認",
    "evidence matches": "エビデンス記載の金額と申請内容記載の金額が一致することを確認した",
    "instruction matches": "指示した内容と購買の内容が相違ないことを確認した",
    "Cancel": "取り消し",
    "Approve": "承認",
    "Please confirm": "確認してください"
  }
}
</i18n>

<script>
export default {
  name: 'ExpenseApproveForm',
  props: {
    expenses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        evidenceMatch: undefined,
        instructionMatch: undefined,
      },
      rules: {
        evidenceMatch: [
          {
            required: true,
            transform: (value) => value || undefined,
            type: 'boolean',
            message: this.$t('Please confirm'),
            trigger: 'change',
          },
        ],
        instructionMatch: [
          {
            required: true,
            transform: (value) => value || undefined,
            type: 'boolean',
            message: this.$t('Please confirm'),
            trigger: 'change',
          },
        ],
      },
      size: 'large',
      isSubmitting: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        const ids = this.expenses.map((expense) => expense.id);

        await this.$store.dispatch('reports/expensesApprove', { ids });
        this.$emit('close');
      } catch (e) {
        console.log(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped></style>
