<template>
  <a-form ref="form" :model="form" :rules="rules" class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h2 class="has-text-primary has-text-weight-bold is-size-4">
        {{ $t('confirm') }}
      </h2>
    </div>
    <a-form-item class="column is-12" :label="$t('Reject comment')" name="remarks">
      <a-textarea v-model:value="form.remarks" :auto-size="{ minRows: 1 }" />
    </a-form-item>

    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-2x"
        style="min-width: 110px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        style="min-width: 110px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Reject') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "confirm": "Confirm to reject",
    "Reject comment": "Reject comment",
    "Please provide a comment": "Please provide a comment",
    "Cancel": "Cancel",
    "Reject": "Reject"
  },
  "ja": {
    "confirm": "却下",
    "Reject comment": "却下理由コメント",
    "Please provide a comment": "コメントを入力してください",
    "Cancel": "取り消し",
    "Reject": "却下"
  }
}
</i18n>

<script>
export default {
  name: 'ExpenseRejectForm',
  props: {
    expenses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['close', 'cancel'],
  data() {
    return {
      form: {
        remarks: undefined,
      },
      rules: {
        remarks: [
          {
            required: true,
            message: this.$t('Please provide a comment'),
            trigger: ['change', 'blur'],
          },
        ],
      },
      size: 'large',
      isSubmitting: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate();
        this.isSubmitting = true;
        const ids = this.expenses.map((expense) => expense.id);

        await this.$store.dispatch('reports/expensesReject', { ids, ...this.form });
        this.$emit('close');
      } catch (e) {
        console.log(e);
      } finally {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped></style>
