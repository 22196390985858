export const STATUS = {
  CREATED: 'created',
  WAITING_APPROVAL: 'waiting approval',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PAYMENT_IN_PROCESS: 'payment in process',
};

export const SUMMARY = {
  CLEANING_FEE: 'cleaning fee',
  ADVANCE_PAYMENT_FEE: 'advance payment fee',
  EMERGENCY_ASSISTANT_FEE: 'emergency assistant fee',
  ADJUSTMENT_FEE: 'adjustment fee',
};

export const BEARER = {
  RLS: 'RLS',
  OWNER: 'Owner',
};

export const PAYOFF_METHOD = {
  RLS_BURDEN: 'RLS Burden',
  OFFSET_WITH_BALANCE: 'Offset with Balance Reports',
};

export const TAX_RATE = {
  PERCENT_10: 10,
  PERCENT_8: 8,
  PERCENT_0: 0,
};
